<template>
  <div class="x-table">
    <b-modal :id="modalFilterId" cancel-title="Annuler" @ok="onFilterOk" ok-variant="primary" ok-title="Ok">
      <template v-slot:modal-title>
        <span v-if="currentFilter">Filtre par {{ currentFilter.label }}</span>
      </template>
      <b-form ref="form" @submit="onFilterSubmit">
        <div v-if="currentFilter && currentFilter.dateFormat">
          <b-row>
            <b-col>
              <date-frame-selector
                :start-date="startDate"
                :end-date="endDate"
                @change="onDateFrameChanged"
              ></date-frame-selector>
            </b-col>
            <b-col cols="2">
              <div style="margin-top: 34px"></div>
              <a class="btn btn-secondary" href @click.prevent="resetFilterDate()">
                <i class="fa fa-eraser"></i>
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <div v-if="currentFilter && (!currentFilter.values || currentFilter.values.length === 0)">
            <radio-select
              :choices="radioChoices"
              :id="'radio-' + modalFilterId"
              inline
              @changed="onRadioChanged($event)"
              :initial-value="radioValue"
              v-show="!isNumeric"
            >
            </radio-select>
            <radio-select
              :choices="radioNumericChoices"
              :id="'radio-' + modalFilterId"
              inline
              @changed="onRadioChanged($event)"
              :initial-value="radioValue"
              v-show="isNumeric"
            >
            </radio-select>
            <b-form-group
              label="Entrez la valeur du filtre"
              label-for="name-input"
              :description="filterDescription"
            >
              <b-row>
                <b-col>
                  <div v-if="isNumeric">
                    <b-row>
                      <b-col>
                        <b-form-input
                          id="num-filter-input"
                          v-model="numericValue"
                          autofocus
                          type="number"
                        ></b-form-input>
                      </b-col>
                      <b-col v-if="numericRadioChoice === 10">
                        <b-form-input
                          id="num-filter-input2"
                          v-model="numericValue2"
                          type="number"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-else>
                    <b-form-input
                      id="filter-input"
                      v-model="filterValue"
                      autofocus
                      type="text"
                      :disabled="!(radioValue && radioValue.id === 1)"
                    ></b-form-input>
                  </div>
                </b-col>
                <b-col cols="2">
                  <a class="btn btn-secondary" href @click.prevent="setFilterValue('')">
                    <i class="fa fa-eraser"></i>
                  </a>
                </b-col>
              </b-row>
            </b-form-group>
          </div>
          <div v-if="currentFilter && currentFilter.values && currentFilter.values.length > 0">
            <b-form-group
              label="Entrez la valeur du filtre"
              label-for="name-input"
              description="Seules les lignes contenant la valeur seront affichées. La casse n'est pas prise en compte"
            >
              <b-form-select
                id="filter-select"
                :value="initialValue"
                @input="setFilterValue"
                :rel="initialValue">
                  <b-form-select-option
                    :value="elt.value"
                    v-for="elt in filterValues()"
                    :key="elt.value"
                  >
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
    <div class="elements-counter" v-if="showCounter">
      <b-row>
        <b-col>{{ counterText() }}</b-col>
        <b-col class="text-right" v-if="hasSelector">
          <counter-label :counter="selectedCount" label="sélectionné"></counter-label>
        </b-col>
      </b-row>
    </div>
    <table
      class="table table-striped"
      :title="tableTitle"
      :class="responsive ? 'table-responsive' : ''"
      v-if="showTable && items.length"
    >
      <tr class="row-header">
        <th v-for="col in columns" :key="col.name + '-header'" :style="colStyle(col)">
          <span v-if="col.selector" class="ignore">
            <b-checkbox
              :id="id + 'select-all'"
              :checked="isAllChecked()"
              :value="true"
              :unchecked-value="false"
              @change="checkAll($event)"
            >
            </b-checkbox>
          </span>
          <span v-else>
            {{ col.label }}
            <span v-if="showColFilter(col)" class="no-print">
              <a
                href
                :class="isActive(col.name, true) ? 'active' : ''"
                @click="sortItems($event, col, true)"
              >
                <i class="fa fa-caret-up"></i>
              </a>
              <a
                href
                :class="isActive(col.name, false) ? 'active' : ''"
                @click="sortItems($event, col, false)"
              >
                <i class="fa fa-caret-down"></i>
              </a>
              <a
                href
                :class="isFilterActive(col) ? 'active' : ''"
                @click="showFilter($event, col)"
              >
                <i class="fa fa-filter"></i>
              </a>
            </span>
          </span>
        </th>
      </tr>
      <tr v-for="item in rows" :key="item[keyField]" class="row-line" :class="item.cssClass || ''">
        <td
          v-for="col in columns" :key="col.name + '-' + item.id"
          :style="colStyle(col)"
          :class="colClass(col, item)"
          :title="col.title || col.label"
        >
          <span v-if="col.selector" class="ignore">
            <b-checkbox
              :id="id + 'select' + item.id"
              :checked="isItemChecked(item)"
              :value="true"
              :unchecked-value="false"
              @change="checkItem(item, $event)"
            >
            </b-checkbox>
          </span>
          <span v-else :class="{ placeholder: isPlaceholder(col, item) }">
            <a
              v-if="col.onClick || (col.isLink && col.isLink(item))"
              :href="col.linkUrl && col.linkUrl(item)"
              class="clickable"
              @click="itemClicked($event, col, item)"
            >
              <span v-html="displayedValue(col, item)"></span>
            </a>
            <a
              v-else-if="col.isLink && col.isLink(item)"
              :href="col.linkUrl && col.linkUrl(item)"
              target="_blank"
            >
              <span v-html="displayedValue(col, item)"></span>
            </a>
            <span v-else v-html="displayedValue(col, item)"></span>
          </span>
        </td>
      </tr>
      <tr class="row-footer" v-if="showFooter">
        <th v-for="col in columns" :key="col.name + '-header'" :style="colStyle(col)" :class="colClass(col)">
          <span v-if="col.colFooterSum">
            {{ sumCol(col) }}
          </span>
          <span v-if="col.colFooter">
            {{ col.colFooter }}
          </span>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import CounterLabel from '@/components/Controls/CounterLabel'
import { currency, dateToString } from '@/filters/texts'
import { makeChoice } from '@/types/base'
import { sum } from '@/utils/math'
import RadioSelect from '@/components/Controls/RadioSelect'
import { formattedPhone, rawPhone } from '@/utils/phones'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import { compareDates, compareNumbers, compareStrings } from '@/utils/sorting'

// alternatives
// https://github.com/joffreyBerrier/vue-spreadsheet
// https://github.com/vccampbell/vue-table-component
export default {
  name: 'x-table',
  components: {
    DateFrameSelector,
    RadioSelect,
    CounterLabel,
  },
  props: {
    items: Array,
    columns: Array,
    showCounter: Boolean,
    verboseName: {
      type: String,
      default: '',
    },
    verboseNamePlural: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    initialSelection: {
      type: Array,
      defaultValue: [],
    },
    keepSelectionOnFilter: {
      type: Boolean,
      defaultValue: false,
    },
    responsive: {
      type: Boolean,
      defaultValue: false,
    },
    title: {
      type: String,
      default: '',
    },
    keyField: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      sortBy: '',
      sortByDate: false,
      sortByNumber: false,
      sortOrder: false,
      filters: {},
      rows: [],
      currentFilter: null,
      initialValue: '',
      startDate: null,
      endDate: null,
      filterValue: '',
      numericValue: null,
      numericValue2: null,
      showTable: true,
      selectedItems: {},
      radioChoices: [
        makeChoice({ id: 1, name: 'Texte', }),
        makeChoice({ id: 2, name: 'Texte vide', }),
        makeChoice({ id: 3, name: 'Texte non vide', })
      ],
      radioNumericChoices: [
        makeChoice({ id: 4, name: 'Égal', }),
        makeChoice({ id: 5, name: 'Différent', }),
        makeChoice({ id: 6, name: 'Inférieur', }),
        makeChoice({ id: 7, name: 'Inférieur ou égal', }),
        makeChoice({ id: 8, name: 'Supérieur', }),
        makeChoice({ id: 9, name: 'Supérieur ou égal', }),
        makeChoice({ id: 10, name: 'Entre', })
      ],
      numericRadioChoice: 4,
    }
  },
  computed: {
    tableTitle() {
      return this.title || this.counterText()
    },
    isNumeric() {
      if (this.currentFilter) {
        return this.currentFilter.currency || this.currentFilter.number
      }
      return false
    },
    filterDescription() {
      if (this.isNumeric) {
        return 'Seules les lignes égales la valeur seront affichées.'
      } else {
        return 'Seules les lignes contenant la valeur seront affichées. La casse n\'est pas prise en compte'
      }
    },
    hasSelector() {
      return this.columns.filter(elt => elt.selector).length
    },
    radioValue() {
      if (this.isNumeric) {
        const selectedChoices = this.radioNumericChoices.filter(elt => (elt.id === this.numericRadioChoice))
        if (selectedChoices.length) {
          return selectedChoices[0]
        }
      } else {
        let radioId = 1
        if (this.filterValue === '*') {
          radioId = 3
        } else if (this.filterValue === '/') {
          radioId = 2
        }
        const selectedChoices = this.radioChoices.filter(elt => (elt.id === radioId))
        if (selectedChoices.length) {
          return selectedChoices[0]
        }
      }
      return null
    },
    selectedCount() {
      let count = 0
      for (let item of this.items) {
        if (this.isItemChecked(item)) {
          count += 1
        }
      }
      return count
    },
    modalFilterId() {
      return 'bv-modal-filter-' + this.id
    },
  },
  watch: {
    items: function() {
      this.filterRows()
    },
    numericValue: function() {
      if (this.numericValue === null) {
        this.filterValue = ''
      } else {
        this.filterValue = '+' + this.numericValue
      }
    },
    columns: function() {
      this.showTable = false
      const that = this
      setTimeout(
        function() {
          that.showTable = true
        },
        100
      )
    },
    initialValue() {
      this.filterValue = this.initialValue
    },
    initialSelection: function() { this.setSelection() },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.rows = this.items.slice() // shallow copy
      this.sortBy = ''
      this.filters = {}
    },
    filterRows: function() {
      this.rows = this.items.slice()
      for (let filter in this.filters) {
        let filterValue = this.filters[filter]
        filterValue = (filterValue === undefined) ? '' : '' + filterValue
        if (filterValue) {
          this.rows = this.rows.filter(
            elt => {
              let cellValue = elt[filter]
              cellValue = (cellValue === undefined) ? '' : cellValue
              if (!cellValue) {
                cellValue = ''
              }
              cellValue = '' + cellValue
              if (filterValue.indexOf('<>') === 0) {
                // date
                const dateRange = filterValue.substring(2).split('#')
                if (!cellValue) {
                  return false
                }
                if (dateRange[0] && compareDates(cellValue, dateRange[0]) < 0) {
                  return false
                }
                if (dateRange[1] && compareDates(cellValue, dateRange[1]) > 0) {
                  return false
                }
                return true
              } else if (filterValue[0] === '+') {
                const cellNumValue = +cellValue
                const filterNumValue = +filterValue.substring(1)
                const radioValue = +this.radioValue.id
                if (radioValue === 5) {
                  return cellNumValue !== filterNumValue
                } else if (radioValue === 6) {
                  return cellNumValue < filterNumValue
                } else if (radioValue === 7) {
                  return cellNumValue <= filterNumValue
                } else if (radioValue === 8) {
                  return cellNumValue > filterNumValue
                } else if (radioValue === 9) {
                  return cellNumValue >= filterNumValue
                } else if (radioValue === 10) {
                  return (cellNumValue >= filterNumValue) && (cellNumValue <= +this.numericValue2)
                } else {
                  return cellNumValue === filterNumValue
                }
              } else if (filterValue === '*') {
                return cellValue !== ''
              } else if (filterValue === '/') {
                return cellValue === ''
              } else {
                return cellValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
              }
            }
          )
        }
      }
      this.sortRows()
      if (!this.keepSelectionOnFilter) {
        this.selectedItems = {} // reset selection
        this.emitSelectionChanged()
      }
    },
    hasFilter() {
      for (let filter in this.filters) {
        let value = this.filters[filter]
        if (value) {
          return true
        }
      }
      return false
    },
    sortRows() {
      const varName = this.sortBy
      const order = this.sortOrder
      const byDate = this.sortByDate
      const byNumber = this.sortByNumber
      if (varName) {
        this.rows = this.rows.sort(
          (elt1, elt2) => {
            let value1 = elt1[varName]
            let value2 = elt2[varName]
            let returnValue = 0
            if (byDate) {
              returnValue = compareDates(value1, value2)
            } else if (byNumber) {
              returnValue = compareNumbers(+value1, +value2)
            } else {
              returnValue = compareStrings(value1, value2)
            }
            if (order) {
              return -returnValue
            }
            return returnValue
          }
        )
      }
    },
    sortItems(event, col, order) {
      event.stopPropagation()
      event.preventDefault()
      this.sortBy = col.name
      this.sortByDate = !!col.dateFormat
      this.sortByNumber = col.number || col.currency
      this.sortOrder = order
      this.sortRows()
    },
    isActive(varName, order) {
      return (this.sortBy === varName) && (this.sortOrder === order)
    },
    isFilterActive(col) {
      return !!(this.filters[col.name])
    },
    showFilter(event, col) {
      event.preventDefault()
      event.stopPropagation()
      this.currentFilter = col
      this.initialValue = '' + (this.filters[col.name] || '')
      if (col.dateFormat) {
        const dateRange = this.initialValue.substring(2).split('#')
        if (dateRange.length === 2) {
          this.startDate = dateRange[0]
          this.endDate = dateRange[1]
        }
      }
      if (this.isNumeric) {
        if (this.initialValue) {
          this.numericValue = +this.initialValue.substring(1)
        } else {
          this.numericValue = null
        }
      }
      this.$bvModal.show(this.modalFilterId)
    },
    onFilterOk() {
      if (this.currentFilter) {
        this.filters[this.currentFilter.name] = this.filterValue
      }
      this.$bvModal.hide(this.modalFilterId)
      this.filterRows()
    },
    onFilterSubmit(event) {
      event.preventDefault()
      if (this.currentFilter) {
        this.filters[this.currentFilter.name] = this.filterValue
      }
      this.$bvModal.hide(this.modalFilterId)
      this.filterRows()
    },
    counterText() {
      let count = this.rows.length
      let text = '' + count
      if (this.verboseName) {
        if (count > 1) {
          text += ' ' + (this.verboseNamePlural || (this.verboseName + 's'))
        } else {
          text += ' ' + this.verboseName
        }
      }
      if (this.hasFilter()) {
        text += ' / ' + this.items.length
      }
      return text
    },
    setFilterValue(event) {
      if (this.isNumeric && !event) {
        this.numericValue = null
      }
      this.filterValue = event
    },
    resetFilterDate() {
      this.startDate = null
      this.endDate = null
    },
    filterValues() {
      if (this.currentFilter && this.currentFilter.values && this.currentFilter.values.length) {
        return [''].concat(
          this.currentFilter.values.map(
            elt => {
              if (typeof elt === 'object') {
                return elt
              } else {
                return { value: elt, name: elt, }
              }
            }
          )
        )
      }
      return []
    },
    getValue(column, item) {
      let value = ''
      if (item) {
        if (column.field && column.subField) {
          const array = item[column.field] || []
          if ((column.index >= 0) && (column.index < array.length)) {
            value = array[column.index][column.subField]
          }
        } else {
          value = item[column.name]
        }
      }
      return value
    },
    isPlaceholder(column, item) {
      const value = this.getValue(column, item)
      return !value
    },
    displayedValue(column, item) {
      let value = this.getValue(column, item)
      if (this.isPlaceholder(column, item)) {
        return column.placeholder || ''
      }
      if (column.onHover) {
        value += (' ' + '<span class="on-hover">' + column.onHover + '</span>')
      }
      if (column.displayAs === 'email') {
        return '<a href="mailto:' + value + '">' + value + '</a>'
      } else if (column.displayAs === 'phone') {
        if (value) {
          return '<a href="tel:' + rawPhone(value) + '">' + formattedPhone(value) + '</a>'
        } else {
          return ''
        }
      } else if (column.displayAs === 'onlyTrue') {
        if (value) {
          return column.displayAsTrue || '<i class="fa fa-check"></i>'
        } else {
          return column.displayAsFalse || ''
        }
      } else if (column.currency) {
        return currency(value)
      } else if (column.dateFormat) {
        return dateToString(value, column.dateFormat)
      } else if (column.contentCallback) {
        return column.contentCallback(value, item)
      }
      return value
    },
    itemClicked(event, column, item) {
      event.preventDefault()
      if (column.onClick) {
        column.onClick(item)
      } else if (column.linkUrl) {
        const path = column.linkUrl(item)
        router.push({ path: path, })
      }
    },
    colStyle(col) {
      let style = ''
      if (col.alignCenter) {
        style = 'text-align: center;'
      } else if (col.alignRight) {
        style = 'text-align: right;'
      }
      if (col.maxWidth) {
        style += ' max-width: ' + col.maxWidth + ';'
      }
      return style
    },
    colClass(col, item) {
      if (col.currency) {
        return 'number currency'
      }
      if (col.number) {
        return 'number'
      }
      if (col.dateFormat === 'DD/MM/YYYY') {
        return 'date'
      }
      if (this.isPlaceholder(col, item)) {
        return 'ignore'
      }
      return ''
    },
    showColFilter(col) {
      return !col.hideFilter
    },
    setSelection() {
      const selectedItems = {}
      if (typeof this.initialSelection !== 'undefined') {
        for (const initialItem of this.initialSelection) {
          selectedItems[initialItem.id] = true
        }
      }
      this.selectedItems = selectedItems
    },
    isItemChecked(item) {
      if (this.selectedItems.hasOwnProperty(item.id)) {
        return this.selectedItems[item.id]
      }
      return false
    },
    isAllChecked() {
      for (let item of this.items) {
        if (!this.isItemChecked(item)) {
          return false
        }
      }
      return true
    },
    checkItem(item, value) {
      this.selectedItems[item.id] = value
      this.selectedItems = { ...this.selectedItems, }
      this.emitSelectionChanged()
    },
    checkAll(value) {
      for (let item of this.rows) {
        this.selectedItems[item.id] = value
      }
      this.selectedItems = { ...this.selectedItems, }
      this.emitSelectionChanged()
    },
    emitSelectionChanged() {
      let items = this.items.filter(elt => this.isItemChecked(elt))
      this.$emit('selectionChanged', { items, all: items.length === this.items.length, })
    },
    sumCol(column) {
      const total = sum(this.rows.map(item => item[column.name]))
      if (column.currency) {
        return currency(total)
      } else {
        return total
      }
    },
    onDateFrameChanged(event) {
      if (event.startDate || event.endDate) {
        this.filterValue = '<>' + (event.startDate || '') + '#' + (event.endDate || '')
      } else {
        this.filterValue = ''
      }
      this.filters[this.currentFilter.name] = this.filterValue
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.filters = { ...this.filters, }
    },
    onRadioChanged(event) {
      if (this.isNumeric) {
        this.numericRadioChoice = event.id
      } else {
        if (event.id === 3) {
          this.filterValue = '*'
        } else if (event.id === 2) {
          this.filterValue = '/'
        } else {
          if ((this.filterValue === '/') || (this.filterValue === '*')) {
            this.filterValue = ''
          } else {
            this.filterValue = this.initialValue
          }
        }
      }
    },
  },
}
</script>

<style lang="less">
  .row-header a {
    color: #fff !important;
  }
  .row-header a.active {
    color: #f0ad4e !important;
  }
  .elements-counter {
    font-weight: bold;
    margin-bottom: 10px;
    padding: 2px;
    background: #ececec;
  }

  td .placeholder {
    visibility: hidden;
  }

  td .placeholder, td .placeholder a{
    color: #888 !important;
    font-style: italic;
  }

  td:hover .placeholder{
    visibility: visible;
  }

  .on-hover {
    visibility: hidden;
  }
  td:hover .on-hover {
    visibility: visible;
  }
  a.clickable {
    cursor: pointer;
  }
  .table tr.row-line.danger td {
    background: #ebafaf !important;
  }
  .table tr.row-line.warning td {
    background: #faffaf !important;
  }
  .table tr.row-line.highlight td {
    background: #beffc0 !important;
  }
</style>
